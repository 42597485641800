<template>
    <div>
        <!--顶部-->
        <div class="banner-content clearfix">
            <!--说明区域-->
            <div class="explain-content">
                <img src="../assets/logo.png" class="logo"/>
                <div class="explain-text">
                    <div class="text-one">请打开C30智能教学客户端进行备授课</div>
                    <div class="text-two">
                        此应用非网页应用，使用请点击右侧按钮启动或下载客户端
                    </div>
                    <div class="text-three">启动前请确保本地已安装客户端</div>
                    <div class="text-four">支持系统：win7 / win8 / win10</div>
                </div>
            </div>
            <!--下载区域-->
            <div class="download-content">
                <div class="prepare-class-content">
                    <div class="open-screen-btn" @click="prepareClass">立即备课</div>
                    <!--<a href="https://fs.iclass30.com/software/common/iclass/C30%E6%99%BA%E8%83%BD%E6%95%99%E5%AD%A6-%E6%95%99%E5%B8%88%E7%AB%AF.exe">-->
                    <a href="https://fs.iclass30.com/software/common/sfpt/C30%E6%99%BA%E8%83%BD%E6%95%99%E5%AD%A6-%E6%95%99%E5%B8%88%E7%AB%AF.exe">
                        <div class="download-btn">
                            <div class="title">
                                <img src="../assets/icon_download.png" class="download-icon"/>
                                <span>下载备课端</span>
                            </div>
                            <div class="explain">在办公室安装使用</div>
                        </div>
                    </a>
                    <div class="word-text" @click="openBkOperateGuide">
                        备课操作指引>>
                    </div>
                </div>
                <div>
                    <div class="open-screen-btn" @click="teachClass">立即授课</div>
                    <!--<a href="https://fs.iclass30.com/software/common/iclass/C30%E6%99%BA%E8%83%BD%E6%95%99%E5%AD%A6.exe">-->
                    <a href="https://fs.iclass30.com/software/common/sfpt/C30%E6%99%BA%E8%83%BD%E6%95%99%E5%AD%A6.exe">
                        <div class="download-btn">
                            <div class="title">
                                <img src="../assets/icon_download.png" class="download-icon"/>
                                <span>下载授课端</span>
                            </div>
                            <div class="explain">在教室安装使用</div>
                        </div>
                    </a>
                    <div class="word-text" @click="openSkOperateGuide">
                        授课操作指引>>
                    </div>
                </div>
            </div>
        </div>
        <!--特色介绍-->
        <div class="special-introduce-content">
            <div class="tab-banner">
        <span
                class="banner-item"
                :class="tabIndex == 'bk' ? 'banner-item-active' : ''"
                @click="changeTab('bk')"
        >轻松备课</span
        >
                <span
                        class="banner-item"
                        :class="tabIndex == 'sk' ? 'banner-item-active' : ''"
                        @click="changeTab('sk')"
                >高效授课</span
                >
            </div>
            <div class="content">
                <!--左侧切换-->
                <div v-if="tabIndex == 'bk'">
                    <template v-for="(item, index) in bkSpecialList">
                        <div
                                @click="changeCurrentImg(item,index)"
                                class="special-item"
                                :class="imgIndex == item.id ? 'special-item-active' : ''"
                        >
                            <img
                                    :src="getRequireIcon(item.icon + '_ed')"
                                    v-if="imgIndex == item.id"
                                    class="icon-img"
                            />
                            <img :src="getRequireIcon(item.icon)" v-else class="icon-img"/>
                            <span>{{ item.text }}</span>
                        </div>
                    </template>
                </div>
                <div v-if="tabIndex == 'sk'">
                    <template v-for="(item, index) in skSpecialList">
                        <div
                                @click="changeCurrentImg(item,index)"
                                class="special-item"
                                :class="imgIndex == item.id ? 'special-item-active' : ''"
                        >
                            <img
                                    :src="getRequireIcon(item.icon + '_ed')"
                                    v-if="imgIndex == item.id"
                                    class="icon-img"
                            />
                            <img :src="getRequireIcon(item.icon)" v-else class="icon-img"/>
                            <span>{{ item.text }}</span>
                        </div>
                    </template>
                </div>
                <!--右侧展示-->
                <div class="special-img">
                    <img :src="currentImg"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {
        findUserInfoByCode,
        findLoginUserInfoByThirdId,
        pinKeUserLogin,
        leZhiUserLogin,
        donShanUserLogin,
        gzjyyUserLogin,
        lgUserLoginAPI,
        getLoginUserInfoAPI,
        xhxxUserLoginAPI,
        chenBangUserLoginAPI,
        nxjyUserLoginAPI
    } from "@/service/api.js";

    export default {
        name: "index",
        computed: {
            ...mapGetters(["screenDownLoadUrl"]),
        },
        data() {
            return {
                tabIndex: "bk",
                //当前选中的图片
                currentImg: "",
                imgIndex: "write",
                skSpecialList: [
                    {
                        id: "write",
                        icon: "write",
                        text: "全景极致书写",
                        imgIcon: "bk-write",
                    },
                    {
                        id: "tool",
                        icon: "tool",
                        text: "智能学科工具",
                        imgIcon: "bk-tool",
                    },
                    {
                        id: "resource",
                        icon: "resource",
                        text: "资源开放融合",
                        imgIcon: "bk-resource",
                    },
                    {
                        id: "multi-screen",
                        icon: "multi-screen",
                        text: "多屏移动交互",
                        imgIcon: "bk-multi-screen",
                    },
                    {
                        id: "comments",
                        icon: "comments",
                        text: "课堂高效讲评",
                        imgIcon: "bk-comments",
                    },
                ],
                bkSpecialList: [
                    {
                        id: "room",
                        icon: "room",
                        text: "开放教学空间",
                        imgIcon: "bk-room",
                    },
                    {
                        id: "excellent",
                        icon: "excellent",
                        text: "优质教学资源",
                        imgIcon: "bk-excellent",
                    },
                    {
                        id: "course",
                        icon: "course",
                        text: "可交互式课件",
                        imgIcon: "bk-course",
                    },
                    {
                        id: "evaluate",
                        icon: "evaluate",
                        text: "智能高效测评",
                        imgIcon: "bk-evaluate",
                    },
                    {
                        id: "analyse",
                        icon: "analyse",
                        text: "全景数据分析",
                        imgIcon: "bk-analyse",
                    },
                ],
                timer: "",
                dataIndex: 0,
                id: this.$route.query.id,
                loading: true,
                code: this.$route.query.code,
                type: this.$route.query.type, //1：腾讯智慧校园  2：品课  3:乐智,4:东山一中,5:贵州云平台，6：曲靖一中,7:协小中台
                jsonInfo: this.$route.query.JsonInfo, //当类别为乐智时，传递的参数
                returnUrl: this.$route.query.returnUrl, //当类别为乐智时，传递的参数 返回教育云平台的链接
                userId: "",
                token: "",
                userType: '',
                showDownloadModal: false,
                clientUrl: "http://127.0.0.1:8069/qry?param=c30dp",
                clientUrlNew: "http://127.0.0.1:10620/qry?param=c30dp", // 原旧端口8069会导致大屏崩溃
                //按钮是否可点击
                isClickBtn: false
            };
        },
        methods: {
            /**
             * 获取图标
             */
            getRequireIcon(name) {
                return require(`@/assets/${name}.png`);
            },
            /**
             * 切换当前图片
             */
            changeCurrentImg(item, index) {
                //重置定时器
                clearInterval(this.timer);
                this.timer = setInterval(this.changeDataShow, 10000);
                this.changeImg(item, index);
                console.log('changeImg:' + new Date())
            },
            /**
             * 切换图片
             */
            changeImg(item, index) {
                this.imgIndex = item.id;
                this.dataIndex = index;
                this.currentImg = this.getRequireIcon(item.imgIcon);
            },
            /**
             * 切换tab
             */
            changeTab(val) {
                this.tabIndex = val;
                this.dataIndex = 0;
                //重置定时器
                clearInterval(this.timer);
                this.timer = setInterval(this.changeDataShow, 10000);
                if (val == "bk") {
                    this.changeImg(this.bkSpecialList[0], 0);
                } else {
                    this.changeImg(this.skSpecialList[0], 0);
                }
                console.log('changeTab:' + new Date())
            },
            /**
             * 打开备课操作手册
             */
            openBkOperateGuide() {
                let url = "https://docs.qq.com/doc/DRnlTbUdhRWFpT25C";
                window.open(url, "_blank");
            },
            /**
             * 打开授课操作手册
             */
            openSkOperateGuide() {
                let url = "https://docs.qq.com/doc/DUElNbmdqR1RsdEZh";
                window.open(url, "_blank");
            },
            /**
             * 根据code获取腾讯智慧校园用户信息
             */
            async getUserInfoByCode() {
                const res = await findUserInfoByCode({
                    code: this.code,
                });

                if (res && parseInt(res.code) === 1) {
                    this.getLoginUserInfoByThirdId(
                        res.data.SourceId,
                        res.data.OrgUserId,
                        res.data.OrgId
                    );
                }
            },
            /**
             * 通过腾讯智慧校园用户id获取同步用户信息
             */
            async getLoginUserInfoByThirdId(sourceId, orgUserId, orgId) {
                let _this = this;
                const res = await findLoginUserInfoByThirdId({
                    sourceId: sourceId,
                    orgUserId: orgUserId,
                    orgId: orgId,
                });

                if (res && parseInt(res.code) === 1) {
                    _this.userId = res.data.id;
                    _this.token = res.data.token;
                    _this.userType = res.data.user_type;
                    //按钮是否可以点击，请求完用户信息才可以点击
                    _this.isClickBtn = true;
                }
            },
            /**
             * 通过code获取同步用户信息
             */
            async getLoginUserInfoByPingkeCode() {
                let _this = this;
                const res = await pinKeUserLogin({
                    encryptData: this.code,
                });

                if (res && parseInt(res.code) === 1) {
                    _this.userId = res.data.id;
                    _this.token = res.data.token;
                    _this.userType = res.data.user_type;
                    //按钮是否可以点击，请求完用户信息才可以点击
                    _this.isClickBtn = true;
                }
            },
            /**
             * 获取本系统的用户信息
             */
            async getLoginUserInfoById() {
                let _this = this;
                const res = await getLoginUserInfoAPI({
                    userId: this.id,
                });

                if (res && parseInt(res.code) === 1) {
                    _this.userId = res.data.id;
                    _this.token = res.data.token;
                    _this.userType = res.data.user_type;
                    //按钮是否可以点击，请求完用户信息才可以点击
                    _this.isClickBtn = true;
                }
            },
            /**
             * 通过JsonInfo获取同步用户信息——乐智
             */
            async getLoginUserInfoByleZhi() {
                let _this = this;
                const res = await leZhiUserLogin({
                    encryptData: _this.jsonInfo,
                });
                if (res && parseInt(res.code) === 1) {
                    _this.userId = res.data.id;
                    _this.token = res.data.token;
                    _this.userType = res.data.user_type;
                    //按钮是否可以点击，请求完用户信息才可以点击
                    _this.isClickBtn = true;
                }
            },
            /**
             * 通过sso获取数据
             */
            async getLoginUserInfoByDoShan() {
                let _this = this;
                if (_this.$route.query.sso_id) {
                    const res = await donShanUserLogin({
                        ssoId: _this.$route.query.sso_id,
                    });
                    if (res && parseInt(res.code) === 1) {
                        _this.userId = res.data.id;
                        _this.token = res.data.token;
                        _this.userType = res.data.user_type;
                        //按钮是否可以点击，请求完用户信息才可以点击
                        _this.isClickBtn = true;
                    }
                } else {
                    window.location.href = 'http://159.75.235.153/login/pc/?redirect_url=' + window.location.href;
                }
            },
            /**
             * 贵州教育云获取数据
             */
            async getLoginUserInfoByGuiZhou() {
                let _this = this;
                const res = await gzjyyUserLogin({
                    ticket: _this.$route.query.ticket,
                });
                if (res && parseInt(res.code) === 1) {
                    _this.userId = res.data.id;
                    _this.token = res.data.token;
                    _this.userType = res.data.user_type;
                    //按钮是否可以点击，请求完用户信息才可以点击
                    _this.isClickBtn = true;
                }
            },
            /**
             * 曲靖一中获取数据
             */
            async getLoginUserInfoByQuJing() {
                let _this = this;
                if (_this.$route.query.code) {
                    const res = await lgUserLoginAPI({
                        code: _this.$route.query.code
                    });
                    if (res && parseInt(res.code) === 1) {
                        _this.userId = res.data.id;
                        _this.token = res.data.token;
                        _this.userType = res.data.user_type;
                        //按钮是否可以点击，请求完用户信息才可以点击
                        _this.isClickBtn = true;
                    }
                } else {
                    window.location.href = 'http://220.164.161.63:20001/OAuth.aspx?response_type=code&appid=95388266&type=Browser&redirect_uri=' + window.location.href + '&state=' + Math.ceil(Math.random() * 1000);
                }
            },
            /**
             * 协小业务中台
             */
            async xhxxUserLogin() {
                let _this = this;
                if (_this.$route.query.ticket) {
                    const res = await xhxxUserLoginAPI({
                        ticket: _this.$route.query.ticket
                    });
                    if (res && parseInt(res.code) === 1) {
                        _this.userId = res.data.id;
                        _this.token = res.data.token;
                        _this.userType = res.data.user_type;
                        //按钮是否可以点击，请求完用户信息才可以点击
                        _this.isClickBtn = true;
                    }
                } else {

                }
            },
            /**
             * 宸邦
             */
            async chenBangUserLogin() {
                let _this = this;
                if (_this.$route.query.code) {
                    const res = await chenBangUserLoginAPI({
                        code: _this.$route.query.code
                    });
                    if (res && parseInt(res.code) === 1) {
                        _this.userId = res.data.id;
                        _this.token = res.data.token;
                        _this.userType = res.data.user_type;
                        //按钮是否可以点击，请求完用户信息才可以点击
                        _this.isClickBtn = true;
                    }
                } else {

                }
            },
            /**
             * 宁夏教育资源公共平台
             */
            async nxjyUserLogin() {
                let _this = this;
                if (_this.$route.query.code) {
                    const res = await nxjyUserLoginAPI({
                        code: _this.$route.query.code
                    });
                    if (res && parseInt(res.code) === 1) {
                        _this.userId = res.data.id;
                        _this.token = res.data.token;
                        _this.userType = res.data.user_type;
                        //按钮是否可以点击，请求完用户信息才可以点击
                        _this.isClickBtn = true;
                    }
                } else {

                }
            },
            /**
             * 启动大屏 使用新端口
             * 思路：先使用新端口启动大屏，如果新端口启动失败再启动8069老端口
             */
            startNewScreenProgram() {
                let _this = this;
                $.ajax({
                    url: this.clientUrlNew,
                    type: "POST",
                    dataType: "json",
                    timeout: 10000,
                    success: function (res) {
                        // 启动大屏成功
                        _this.loading = false;
                        // 关闭当前窗口
                        _this.closeWin();
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        //新端口启动失败
                        _this.startScreenProgram();
                    },
                });
            },
            /**
             * 启动大屏
             */
            startScreenProgram() {
                let _this = this;
                $.ajax({
                    url: this.clientUrl,
                    type: "POST",
                    dataType: "json",
                    timeout: 10000,
                    success: function (res) {
                        // 启动大屏成功
                        _this.loading = false;
                        // 关闭当前窗口
                        _this.closeWin();
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        //大屏启动失败
                        if (XMLHttpRequest.readyState === 0) {
                            // _this.startFailCallback();
                            _this.showDownloadModal = true;
                            _this.loading = false;
                        }
                    },
                });
            },
            /**
             * 启动大屏失败回调事件
             */
            startFailCallback(params, type) {
                let _this = this;
                location.href = "zndp://uid=" + this.userId + "&token=" + this.token;
                // location.href = 'znpc://uid=' + this.userId + '&token=' + this.token;
                console.log("zndp://uid=" + this.userId + "&token=" + this.token);
                // this.checkProgram();
                setTimeout(function () {
                    _this.startNewScreenProgram();
                }, 5000);
            },
            /**
             * 立即备课
             */
            prepareClass() {
                if (this.isClickBtn) {
                    if (this.userType == '1') {
                        location.href = "znpc://uid=" + this.userId + "&token=" + this.token;
                    } else {
                        alert('该应用为教师应用，请登录教师账号获取更多服务')
                    }
                }
            },
            /**
             * 立即授课
             */
            teachClass() {
                if (this.isClickBtn) {
                    if (this.userType == '1') {
                        location.href = "zndp://uid=" + this.userId + "&token=" + this.token;
                    } else {
                        alert('该应用为教师应用，请登录教师账号获取更多服务')
                    }
                }
            },
            /**
             * 检查客户端是否存在
             */
            checkProgram: function () {
                let _this = this;
                // _this.enterHandler && clearTimeout(self.enterHandler);
                // _this.enterHandler = setTimeout(function () {
                $.ajax({
                    url: _this.clientUrl,
                    type: "GET",
                    timeout: 10000,
                    success: function (response) {
                        // 启动大屏成功
                        console.log("大屏客户端存在");
                        _this.loading = false;
                        // 关闭当前窗口
                        _this.closeWin();
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        if (XMLHttpRequest.readyState === 0) {
                            //启动大屏失败
                            console.log("大屏客户端不存在");
                            // _this.loading = false;
                            // _this.showDownloadModal = true;
                            _this.startFailCallback();
                            // _this.checkProgramNew();
                        }
                    },
                });
                // }, 8000);
            },
            /**
             * 检查客户端是否存在
             */
            checkProgramNew: function () {
                let _this = this;
                $.ajax({
                    url: _this.clientUrlNew,
                    type: "GET",
                    timeout: 10000,
                    success: function (response) {
                        // 启动大屏成功
                        console.log("大屏客户端存在");
                        _this.loading = false;
                        // 关闭当前窗口
                        _this.closeWin();
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        if (XMLHttpRequest.readyState === 0) {
                            //启动大屏失败
                            console.log("大屏客户端不存在");
                            // _this.loading = false;
                            // _this.showDownloadModal = true;
                            _this.startFailCallback();
                        }
                    },
                });
            },
            /**
             * 下载大屏安装包
             */
            downloadClient() {
                var elemIF = document.createElement("iframe");
                elemIF.src = this.screenDownLoadUrl;
                elemIF.style.display = "none";
                document.body.appendChild(elemIF);
            },
            /**
             * 关闭当前窗口
             */
            closeWin() {
                this.showDownloadModal = false;
                window.opener = null; // 切断引用 ie低版本的需要赋值为null
                window.open("", "_self"); // 重置opener为当前页面自身
                window.close(); // 此时关闭不会提示
            },
            /**
             *切换展示的数据
             */
            changeDataShow() {
                this.dataIndex++;
                console.log(this.dataIndex + '-' + new Date())
                if (this.tabIndex == "bk") {
                    if (this.dataIndex == this.bkSpecialList.length) {
                        this.dataIndex = 0;
                    }
                    this.changeImg(this.bkSpecialList[this.dataIndex], this.dataIndex);
                } else {
                    if (this.dataIndex == this.skSpecialList.length) {
                        this.dataIndex = 0;
                    }
                    this.changeImg(this.skSpecialList[this.dataIndex], this.dataIndex);
                }
            },
        },
        mounted() {
            this.changeImg(this.bkSpecialList[0], 0);
            // 当来源为本系统
            if (this.type == 1) {
                this.getLoginUserInfoById();
            } else if (this.type == 2) {
                this.getLoginUserInfoByPingkeCode();
            } else if (this.type == 3) {
                // 当来源为乐智时根据JsonInfo 获取用户信息
                this.getLoginUserInfoByleZhi();
            } else if (this.type == 4) {
                // 当来源为东山一中时从sso获取数据
                this.getLoginUserInfoByDoShan();
            } else if (this.type == 5) {
                // 当来源为贵州教育云
                this.getLoginUserInfoByGuiZhou();
            } else if (this.type == 6) {
                // 当来源为曲靖一中
                this.getLoginUserInfoByQuJing();
            } else if (this.type == 7) {
                // 当来源为协小业务中台
                this.xhxxUserLogin();
            } else if (this.type == 8) {
                // 当来源为宸邦
                this.chenBangUserLogin();
            } else if (this.type == 9) {
                // 当来源为宁夏教育资源
                this.nxjyUserLogin();
            } else {
                this.getUserInfoByCode();
            }
            this.timer = setInterval(this.changeDataShow, 10000);
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
    };
</script>

<style lang="scss" scoped>
    @import "../style/screenMain";

    a {
        text-decoration: none;
    }
</style>
